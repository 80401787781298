import { useState } from "react";
import { scroll } from "../../utils";

import Notice from "../../shared/Notice";
import Hdg from "../../shared/Hdg";

import GuestInfoForm from "../../shared/PAGuestInfoForm";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/pro-light-svg-icons";

function Registration({ guest, details }) {
	const [notice, setNotice] = useState({});

	const handleSuccess = (text) => {
		setNotice({
			type: "success",
			text,
		});

		scroll();
	};

	return (
		<div>
			<Hdg text="View / Update Registration Details" dash={false} pa={true} />

			<p>
				<FontAwesomeIcon icon={faCircleCheck} className="success" /> You have
				been successfully added as a participate with{" "}
				{guest.registration.company} at the 2024 CME Group Tour Championship
				Pro-Am on {details.eventDate}.
			</p>

			<p
				style={{
					paddingBottom: "12.5px",
				}}
			>
				Use the form below to update any of your registration details, if you
				have questions or need to change your attendance status, please contact{" "}
				{guest.registration.name} with {guest.registration.company} at{" "}
				{guest.registration.phone} or email{" "}
				<a href={`mailto:${guest.registration.email}`}>
					{guest.registration.email}
				</a>
				.
			</p>

			{!details.phone && (
				<div style={{ marginTop: "0", paddingBottom: "20px" }}>
					<Notice
						type="notice"
						text="Your registration details need to be completed, please use the form below."
					/>
				</div>
			)}

			{notice.type && (
				<div style={{ marginTop: "0", paddingBottom: "20px" }}>
					<Notice type={notice.type} text={notice.text} />
				</div>
			)}

			<GuestInfoForm guest={guest} details={details} success={handleSuccess} />
		</div>
	);
}

export default Registration;
